'use strict'

const cf = $('#configurator');

let changeImage = (index) => {
    const targetImage = cf.find('.conf-image').find('img').eq(index);
    if(targetImage){
        cf.find('.conf-image').find('img').removeClass('active');
        targetImage.addClass('active');
    }
}

$(document).ready(function(){
    cf.find('.conf-nav').on('click', '.conf-selector', function(e){
        e.preventDefault();
        const index = $(this).closest('li').index();
        changeImage(index);
    })

})